<template>
    <header>
    <div class='logo'><a href="https://www.karitas.si/esos"><img src="@/assets/esoslogo.svg" alt="Esos"></a></div>
    <h2 v-if="title">{{ title }}</h2>
    <div class="navigation-toggle">
        <span v-if="newDataAvailable" class="dot" title="Nova verzija na voljo">&bull;</span>
        &#9776;
    </div>
  </header>
  <nav>
      <div class="navigation-toggle"><span class="close">+</span></div>
      
      <div class="new-data-available" v-if="newDataAvailable">
        <h4>Na voljo je nova verzija</h4>
        <p>Naložena je bila nova verzija podatkov. S skikom na spodnji gumb lahko podatke posodobite</p>
        <p>
            <em>Pozor!</em> S tem boste ponastavili vse vprašalnike v začetno stanje. <br>
            Če ste ravno med izpolnjevanjem vprašalnika, priporočam, da anketo najprej zaključite in si ogledate rezultat.
        </p>
        <button @click="resetData()">Posodobi</button>   
      </div>
      
      <div>
        <ul>
            <li><RouterLink to="/" @click="close">Začetna stran</RouterLink></li>
            <li class="heading"><h4>Vprašalniki</h4></li>
            <li v-for="( questionaire, index ) in questions.questionaires" 
                :key="index">
                <RouterLink 
                :to="{name: 'vprasalnik', params: { questionaire: questionaire.slug}}"
                 @click="close">
                    {{ questionaire.name }}
                </RouterLink>
            </li>
        </ul>
        <ul>
            <li><RouterLink :to="{ name: 'page', params: { page: 'vprasalnik-in-pogoji-uporabe'} }" @click="close">Pogoji uporabe</RouterLink></li>
            <li><a href="https://www.karitas.si/esos/">Video posvet</a></li>
        </ul>
        <ul class="kontakt">
            <li><h4>Kontakt</h4></li>
            <li><a href="mailto:info@karitas.si">info@karitas.si</a></li>
            <li><a href="tel:+386 40 464 340">+386 40 464 340</a></li>
        </ul>
      </div>
      
      <div v-if="answers.answers.length">
        <button @click="reset()">Zbriši podatke in začni znova</button>
      </div>
      
      <div class="is-debug" v-if="isDebug()">
        <button @click="toggleDebug">Debug</button>
        <div class="result" v-if="showDebug">
          <div>
              Seštevek točk: {{ answers.result }} 
          </div>
          <div>
              Oznake: <ul v-if="answers.tags">
                  <li v-for="(tag, index) in answers.tags" :key="index">{{ index }}: {{ tag }}</li>
              </ul>
          </div>
        </div>
      </div>

      <div class="data-version">
        Verzija: {{ appVersion }}.{{ dataVersion }} 
      </div>
    </nav>
</template>

<script setup>
import { onMounted, ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios'
import { useAnswersStore } from '@/stores/answers'
import { useQuestionsStore } from '@/stores/questions'

/** Use ... */
const route = useRoute()
const router = useRouter()
const answers = useAnswersStore()
const questions = useQuestionsStore()

/** Props */
const props = defineProps(
    ['title']
)

/** slide in navigation toggling */
const close = () => {
    const menu = document.querySelector('nav')
    menu.classList.remove('opened')
}

const toggleNavigation = () => {
    const toggleNav = document.querySelectorAll('.navigation-toggle')

    const menu = document.querySelector('nav')

    toggleNav.forEach( (element) => {
        element.addEventListener( 'click', () => {
            menu.classList.toggle('opened')
        })
    })
}

onMounted(
    () => {
        toggleNavigation()
    }
)

/** Links to questionaires */


onMounted(
    () => {
        questions.getQuestionaires()
    }
)

/** reset form */
const reset = () => {
  const consent = answers.consent
  answers.$reset()
  answers.consent = consent
  answers.apiToken = ""
  localStorage.removeItem("esosToken")
  questions.activeAnswer = 0
  questions.currentIndex = 0
  questions.currentQuestionId = questions.questions[0].id
  router.push( { name: 'home' } )
  window.scrollTo(0,0)
}

/** Data Version and Reset */

const dataVersion = ref(0)
const newDataVersion = ref(0)
const newDataAvailable = ref(false)
/** app version for debug */
const appVersion = process.env.VUE_APP_VERSION

const resetData = () => {
  // we may ask for confirmation first, or simply hide pending update untill answers are present
  localStorage.clear()
  reset()
  router.push( { name: 'home' } )
  location.reload()
}

const getToken = () => {
    answers.apiToken = localStorage.getItem('esosToken')

    axios
        .post(process.env.VUE_APP_ROOT_API + '/esos/v1/token', {
            'token': answers.apiToken
        })
        .then( (response) => {
            answers.apiToken = response.data.token
            localStorage.setItem('esosToken', response.data.token)
        })
}

const getVersion = () => {
  dataVersion.value = localStorage.getItem('dataVersion')

  axios
    .get(process.env.VUE_APP_ROOT_API + '/esos/v1/data-version')
    .then( response => {
      newDataVersion.value = response.data.data_version

      if( ! dataVersion.value ) {
        dataVersion.value = newDataVersion.value
        localStorage.setItem('dataVersion', dataVersion.value)
      } else if ( dataVersion.value !== newDataVersion.value ) {
        newDataAvailable.value = true
      }

    })
}

onMounted( () => {
    getToken()
    getVersion() 
})

/** Debug */
const showDebug = ref(false)

const toggleDebug = () => {
  showDebug.value = ! showDebug.value
}

const isDebug = () => {
    if( route.query.debug == "1" ) {
        answers.isDebug = true;
    } else if ( route.query.debug == "0") {
        answers.isDebug = false
    }

    return answers.isDebug
}

/** Frontend stuff: resizing paddings in function of header height */
const resize = () => {
    const main = document.querySelector('main')
    
    const header = document.querySelector('header')

    if( header ) {
        main.style.paddingTop = header.clientHeight + "px"
        const headerObserver = new ResizeObserver( entries => main.style.paddingTop = entries[0].target.clientHeight + "px" )
        headerObserver.observe(header)
    }

    const footer = document.querySelector('footer')

    if( footer ) {
        main.style.paddingBottom = footer.clientHeight + 10 + "px"
        const footerObserver = new ResizeObserver( e => main.style.paddingBottom = e[0].target.clientHeight + 10 + "px")
        footerObserver.observe(footer)
    }

}

onMounted( () => {
  resize()
})

</script>

<style lang="less">
    header {
        position: fixed;
        top:0;
        left:0;
        right:0;
        background-color: var(--background);
        z-index: 10;

        h2 {
            max-width:860px;
            margin: 0 auto;
            padding: 1em;
        }

    }
    .logo {
        width: 100%;
        text-align: center;
        img {
            margin: .5em auto;
            height:80px;
        }
    }
    .navigation-toggle {
        font-size: 2rem;
        padding: .5rem 1rem;
        text-align: right;
        cursor: pointer;
        position: absolute;
        right:0;
        top:0;
        // z-index: 100;
        span.dot {
            color: var( --attention );
            position:absolute;
            top:.1rem;
            left:.4rem;
        }
        
        .close {
            transform: rotate(45deg);
            display: inline-block;
            font-size: 3rem;
            margin-top: -1rem;
            margin-right: 0rem;
        }
    }
    nav {
        display: grid;
        z-index: 30;
        position: fixed;
        overflow-y: scroll;
        background-color: var( --main );
        color: var( --inverted );
        padding: 2.5rem 1em 1em 1em;
        top:0;
        right:0;
        bottom:0;
        width: 340px;
        max-width: 70vw;
        transform: translateX( 400px );
        transition: transform 300ms ease-out;

        button {
            background-color: var( --inverted );
            color: var( --text );
        }

        &.opened {
            transform: translate( 0 )
        }

        ul {
        padding:0; 
        }
        li {
            list-style: none;
            padding:.5em;
            h4 {
                margin-bottom:.1em;
                font-size: 1.1rem;
            }
            &:hover {
                background-color: var( --main-light );
            }
            &.heading:hover {
                background-color: initial;
            } 
        }
        a {
            color: var( --inverted );
        }

        .new-data-available {
            background-color: var( --secondary );
            margin-left: -1em;
            margin-right: -1em;
            padding: 1em;
        }

        .is-debug {
            align-self: end;
        }

        .data-version {
            position:absolute;
            bottom:0; 
            right: 0; 
            padding: .5em;  
        }
    }

</style>